import { Model, ModelAttributes, model, modelConfig } from "@mixitone/oom";
import Account from "./Account";
import ClubPlayer from "./ClubPlayer";
import ClubPlayerToken from "./ClubPlayerToken";

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    created_at: {
      type: "datetime",
    },
    is_deleted: {
      type: "boolean",
    },
    metadata: {
      type: "json",
      jsonType: null,
    },
    token_type: {
      type: "string",
    },
    token_value: {
      type: "string",
    },
    updated_at: {
      type: "datetime",
    },
  },
  {},
  {},
);

interface Token extends ModelAttributes<typeof config> {}

@model("tokens", config)
class Token extends Model<typeof config> {
  static async findOrCreate(value: string, type: string) {
    let token = await Token.query()
      .eq("account_id", Account.current!.uniqueId)
      .eq("token_value", value)
      .first();

    if (!token) {
      // Try finding with 0x90 0x00 status code
      const uidArray = Uint8Array.from(atob(value), (c) => c.charCodeAt(0));
      // Append [0x90, 0x00] (the status word)
      const uidWithStatus = new Uint8Array(uidArray.length + 2);
      uidWithStatus.set(uidArray);
      uidWithStatus.set([0x90, 0x00], uidArray.length);
      // Re-encode to base64
      const uidWithStatusBase64 = btoa(String.fromCharCode(...uidWithStatus));
      token = await Token.query()
        .eq("account_id", Account.current!.uniqueId)
        .eq("token_value", uidWithStatusBase64)
        .first();
    }

    if (!token) {
      token = new Token({
        account_id: Account.current!.uniqueId,
        token_value: value,
        token_type: type,
      });
      await token.save();
    }

    return token;
  }

  async playerForClub(clubId: string) {
    return await ClubPlayer.query()
      .eq("club_id", clubId)
      .joins(ClubPlayerToken.query().eq("token_id", this.uniqueId))
      .first();
  }

  async mostRecentPlayerForAccount() {
    return await ClubPlayer.query()
      .eq("account_id", this.account_id)
      .joins(ClubPlayerToken.query().eq("token_id", this.uniqueId))
      .orderBy("created_at", "desc")
      .first();
  }
}

export default Token;
