import { IconComponent } from "./Icon";

const StartIcon: IconComponent = ({ className }) => (
  <svg
    className={`w-[15px] ${className}`}
    viewBox="2 2 21 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 18.5v-12l10 6-10 6z"
      fill="current"
      stroke="current"
      strokeWidth="1.2"
    />
  </svg>
);

export default StartIcon;
