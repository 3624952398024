import tauri, { Tauri } from "lib/tauri";
import { AuthEvent, Session, SessionAdapter } from "./SessionAdapter";

export const supabaseOptions = {
  url: import.meta.env.VITE_SUPABASE_URL,
  anonKey: import.meta.env.VITE_SUPABASE_ANON_KEY,
};

enum SessionEvent {
  SIGNED_IN = "session:signed_in",
  SIGNED_OUT = "session:signed_out",
  TOKEN_REFRESH = "session:token_refresh",
}

interface TauriUser {
  id: string;
  email: string;
  app_metadata: Record<string, any>;
  user_metadata: Record<string, any>;
}

interface TauriSession {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  expires_at: number;
  token_type: string;
  user: TauriUser;
}

interface SessionEventPayload {
  event: SessionEvent;
  id: number;
  payload: null | TauriSession;
  windowLabel: string;
}

export class TauriSessionAdapter extends SessionAdapter {
  tauri: Tauri;

  constructor() {
    super();
    this.tauri = tauri;
  }

  get canSignUp() {
    return false;
  }
  get canSignIn() {
    return true;
  }
  get canSignOut() {
    return true;
  }
  get canResetPassword() {
    return false;
  }

  async getSession(): Promise<Session | null> {
    const session = await this.tauri.invoke("plugin:session|get_session");
    return session as Session | null;
  }

  async getUser() {
    return { error: new Error("Not implemented"), data: null };
  }

  async initialize() {
    const listeners = await Promise.all(
      Object.values(SessionEvent).map((event) =>
        this.tauri.listen(event, (payload) => this.handle(event, payload as any)),
      ),
    );

    console.log("TauriSessionAdapter initialized", listeners);
    await this.tauri.invoke("plugin:session|load_session", {
      url: supabaseOptions.url,
      apiKey: supabaseOptions.anonKey,
    });
  }

  async signInWithPassword(email: string, password: string) {
    try {
      const response = await this.tauri.invoke<TauriSession>("plugin:session|sign_in_with_password", {
        email,
        password,
      });

      return {
        error: null,
        data: {
          session: {
            ...response,
            adapter: "tauri",
          },
          user: response.user,
        },
      };
    } catch (e) {
      const error = typeof e === "string" ? new Error(e) : (e as Error);
      return { error };
    }
  }

  async resetPasswordForEmail(email: string, redirectTo: string) {
    const response = await this.tauri.invoke("plugin:session|reset_password_for_email", {
      email,
      redirectTo,
    });
    console.log(response);
    return { error: null, data: {} };
  }

  async signOut() {
    try {
      await this.tauri.invoke("plugin:session|sign_out");
      return { data: undefined, error: null };
    } catch (e) {
      const error = e as Error;
      return { error };
    }
  }

  handle(event: string, payload: SessionEventPayload) {
    switch (event) {
      case SessionEvent.SIGNED_IN:
        if (!payload.payload) {
          console.log(event, payload);
          throw new Error("No payload");
        }
        this.emit(AuthEvent.SIGNED_IN, { ...payload.payload, adapter: "tauri" });
        break;
      case SessionEvent.SIGNED_OUT:
        this.emit(AuthEvent.SIGNED_OUT, null);
        break;
      case SessionEvent.TOKEN_REFRESH:
        if (!payload.payload) throw new Error("No payload");
        this.emit(AuthEvent.TOKEN_REFRESHED, { ...payload.payload, adapter: "tauri" });
        break;
    }
  }

  async rpc<T>(
    functionName: string,
    args: any,
  ): Promise<
    | {
        error: undefined;
        data: T;
      }
    | { error: Error; data: undefined }
  > {
    const response = await tauri.invoke("plugin:session|rpc", { functionName, args });
    return response as any;
  }
}
