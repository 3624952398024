import { DropdownButton, DropdownMenuItem } from "@mixitone/components";
import { AppController } from "controllers/AppController";
import { ApplicationView } from "@mixitone/mvc";
import { Button } from "@mixitone/components/Controls/Button";

const ClubMenu = () => {
  const appController = AppController.use();
  const { currentClub, clubs } = appController.state;

  if (clubs.length === 1)
    return (
      <Button
        className="border border-slate-500 bg-slate-700 sm:block"
        onClick={() => {
          appController.actionSetCurrentClub(clubs[0]);
        }}
      >
        {clubs[0].name}
      </Button>
    );

  return (
    <DropdownButton
      buttonProps={{
        kind: "select",
        className:
          "text-white stroke-gray-300 border-slate-500 bg-slate-700 pl-3 hover:bg-slate-600 sm:block border",
      }}
      button={currentClub?.name}
      arrow
    >
      {clubs.map((club) => (
        <DropdownMenuItem
          key={`club-dropdown-item-${club.id}`}
          onClick={() => {
            appController.actionSetCurrentClub(club);
          }}
          value={club}
        >
          {club.name}
        </DropdownMenuItem>
      ))}
    </DropdownButton>
  );
};

export default ApplicationView(ClubMenu);
