import { Field, InsertQuery, Query, UpdateQuery } from "..";
import { CountResponse, DataResponse, DatabaseAdapter } from "./DatabaseAdapter";

export default class NullDatabaseAdapter extends DatabaseAdapter {
  convertType(field: Field, value: any) {
    return value;
  }
  insert<Data>(query: InsertQuery<any, any>, data: Record<string, any>): Promise<DataResponse<Data>> {
    throw new Error("Method not implemented.");
  }
  update<Data>(query: UpdateQuery<any, any>, data: Record<string, any>): Promise<DataResponse<Data>> {
    throw new Error("Method not implemented.");
  }
  delete(query: UpdateQuery<any, any>): Promise<CountResponse> {
    throw new Error("Method not implemented.");
  }

  override async execute<Data>(query: Query<any, any>): Promise<DataResponse<Data>> {
    return { data: [] };
  }

  override async count(query: Query<any, any>): Promise<CountResponse> {
    return { count: 0 };
  }
}
