import { AppController } from "controllers/AppController";
import { useEffect } from "react";

export const useTitle = (title: string | undefined | null | React.ReactNode) => {
  const appController = AppController.use();

  useEffect(() => {
    if (!title) return;
    const copiedTitle = `${title}`;
    const index = appController.actionAppendTitle(copiedTitle);
    return () => appController.actionRemoveTitle(copiedTitle, index);
  }, [title]);
};
