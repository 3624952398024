import { Model, ModelAttributes, ValidationError, model, modelConfig } from "@mixitone/oom";
import { isNil } from "@mixitone/util";
import Debug from "debug";
import { setAccountId } from "./concerns/SetAccountId";

const debug = Debug("oom:model:Game");

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    game_set_id: {
      type: "string",
    },
    index: {
      type: "number",
    },
    is_deleted: {
      type: "boolean",
    },
    player_1_id: {
      type: "string",
    },
    player_2_id: {
      type: "string",
    },
    player_3_id: {
      type: "string",
    },
    player_4_id: {
      type: "string",
    },
    players: {
      type: "json",
      jsonType: {
        locked: false,
        current: [],
        winners: [],
        losers: [],
      } as {
        locked?: boolean;
        current: Array<string | undefined>;
        winners?: Array<string>;
        losers?: Array<string>;
      },
    },
    reconciled: {
      type: "boolean",
    },
    updated_at: {
      type: "datetime",
    },
  },
  {},
  {},
);

interface Game extends ModelAttributes<typeof config> {}

@setAccountId
@model("games", config)
class Game extends Model<typeof config> {
  static override tableName: "games" = "games";
  static override config = config;

  undoStack: Array<(typeof this)["players"]> = [];
  private _enableUndo = true;

  clearUndo() {
    this.undoStack = [];
  }

  startBatchUndo() {
    if (!this._enableUndo) return;
    this._enableUndo = false;
    this.undoStack.push({
      locked: this.players?.locked,
      current: this.players?.current.slice() ?? [],
      winners: this.players?.winners?.slice() ?? [],
      losers: this.players?.losers?.slice() ?? [],
    });
  }

  endBatchUndo() {
    this._enableUndo = true;
  }

  get locked() {
    return this.players?.locked ?? false;
  }

  lock() {
    this.players = {
      ...this.players!,
      locked: true,
    };
  }

  unlock() {
    this.players = {
      ...this.players!,
      locked: false,
    };
  }

  get playerCount() {
    return this.activePlayerIds().length;
  }

  activePlayerIds() {
    return this.playerIds.filter((id) => !isNil(id)) as string[];
  }

  get playerIds() {
    const playerIds = this.players?.current ?? [];
    return [playerIds[0], playerIds[1], playerIds[2], playerIds[3]].map((id) => {
      if (isNil(id)) return undefined;
      return id;
    }) as [string | undefined, string | undefined, string | undefined, string | undefined];
  }

  set playerIds(ids: Array<string | undefined>) {
    this.players = {
      ...this.players!,
      current: [ids[0], ids[1], ids[2], ids[3]],
    };
  }

  get winners() {
    return new Set(this.players?.winners ?? []);
  }

  get losers() {
    return new Set(this.players?.losers ?? []);
  }

  hasPlayer(id: string) {
    return this.playerIds.includes(id);
  }

  addPlayer(id: string) {
    if (this.full()) throw new Error("This game is full, cannot add a player");
    const ids = this.playerIds.slice();
    const index = ids.findIndex((id) => isNil(id));
    ids[index] = id;
    this.playerIds = ids;
  }

  addPlayers(ids: Array<string>) {
    ids.forEach((id) => this.addPlayer(id));
  }

  setPlayerAt(index: number, id: string | undefined) {
    const ids = this.playerIds.slice();
    ids[index] = id;
    this.playerIds = ids;
  }

  removePlayers(ids: Array<string>) {
    ids.forEach((id) => this.removePlayer(id));
  }

  removePlayer(id: string) {
    if (!this.playerIds.includes(id)) return;

    this.playerIds = this.playerIds.map((playerId) => {
      if (playerId === id) return undefined;
      return playerId;
    });
  }

  swapPlayer(oldId: string, newId: string) {
    if (!this.playerIds.includes(oldId)) throw new Error("Cannot swap player that is not in game");
    const index = this.playerIds.findIndex((id) => id === oldId);
    this.setPlayerAt(index, newId);
  }

  full() {
    return this.playerCount === 4;
  }

  valid() {
    return [2, 4].includes(this.playerCount);
  }

  get canUndo() {
    return this.undoStack.length > 0;
  }

  undo() {
    if (!this.canUndo) return;

    const undoState = this.undoStack.pop();
    if (!undoState) return;
    this.players = { ...undoState };
  }

  clear() {
    this.playerIds = [undefined, undefined, undefined, undefined];
  }

  override create() {
    if (isNil(this.game_set_id)) throw new ValidationError({ game_set_id: "Game set id is required" });
    return super.create();
  }

  isWinner(playerId: string): boolean {
    return this.winners.has(playerId) ?? false;
  }

  isLoser(playerId: string): boolean {
    return this.losers.has(playerId) ?? false;
  }

  markWon(playerId: string) {
    let { winners, losers } = this;
    const playerIds = this.activePlayerIds();
    if (!playerIds.includes(playerId)) throw new Error("Player is not in this game");

    if (this.winners.has(playerId)) return this.unmarkWon(playerId);
    if (playerIds.length % 2 === 0 && winners.size === playerIds.length / 2) return;

    winners.add(playerId);
    losers.delete(playerId);

    if (playerIds.length % 2 === 0 && winners.size === playerIds.length / 2) {
      losers = new Set(playerIds.filter((id) => !winners.has(id!)));
    }

    this.players = {
      ...this.players!,
      winners: Array.from(winners),
      losers: Array.from(losers),
    };
  }

  unmarkWon(playerId: string) {
    const { winners, losers } = this;
    if (!winners.has(playerId)) throw new Error("Player is not marked as won");

    winners.delete(playerId);
    losers.clear();

    this.players = {
      ...this.players!,
      winners: Array.from(winners),
      losers: Array.from(losers),
    };
  }

  save(): Promise<void> {
    debug("Saving game", this.index, this.id);
    return super.save();
  }

  markClean(): void {
    debug("Marking game clean", this.index, this.id);
    super.markClean();
  }
}

export default Game;
