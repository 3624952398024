import { Field } from "..";

export type ColType = Field["type"];

type BoundableType = string | number | boolean | string[] | number[];

export type BoundValue = [BoundableType, ColType];

export class BoundQuery {
  static blank() {
    return new BoundQuery("", []);
  }

  static sql(sql: string) {
    return new BoundQuery(sql);
  }

  static join(query1: BoundQuery, query2: BoundQuery, joiner: string) {
    return query1.clone().join(query2, joiner);
  }

  constructor(
    public sql: string,
    public values: BoundValue[] = [],
  ) {}

  get isBlank() {
    return this.sql === "";
  }

  clone() {
    return new BoundQuery(this.sql, this.values);
  }

  push(sql: string) {
    this.sql += sql;
    return this;
  }

  bind(value: BoundableType, colType: ColType = "string") {
    this.values.push([value, colType]);
    return this;
  }

  push_bind(value: BoundableType, colType: ColType = "string") {
    if (Array.isArray(value)) {
      if (value.length === 0) {
        throw new Error("Cannot bind an empty array");
      }

      this.sql += `(${value.map(() => "?").join(", ")})`;
      this.values.push(...value.map((v): BoundValue => [v, colType]));
    } else {
      this.sql += "?";
      this.values.push([value, colType]);
    }
    return this;
  }

  merge(other: BoundQuery) {
    if (other.isBlank) return this;
    if (!this.isBlank) this.sql += " ";
    this.sql += other.sql;
    this.values = this.values.concat(other.values);
    return this;
  }

  join(other: BoundQuery, joiner: string) {
    this.sql += joiner + other.sql;
    this.values = this.values.concat(other.values);
    return this;
  }

  get asArgs(): [string, any[]] {
    return [this.sql, this.values.map(functionValue)];
  }

  /**
   * Construct the sql query with ? placeholders replaced with values
   */
  build(): string {
    const values = [...this.values];

    return this.sql.replace(/\?/g, (match) => {
      const value = values.shift();
      if (!value) {
        throw new Error("Not enough values to bind");
      }
      return escapeOrFunctionValue(value);
    });
  }
}

const FUNCTIONS: Record<ColType, string[]> = {
  datetime: ["today"],
  string: [],
  number: [],
  boolean: [],
  enum: [],
  json: [],
  "number[]": [],
  "string[]": [],
};

function functionValue(boundValue: BoundValue): BoundableType {
  const [value, colType] = boundValue;

  const functions = FUNCTIONS[colType];
  if (functions && typeof value === "string" && functions.includes(value)) {
    return `${value}()`;
  } else {
    return value;
  }
}

function escapeOrFunctionValue(boundValue: BoundValue): string {
  const [value, colType] = boundValue;

  const functions = FUNCTIONS[colType];
  if (functions && typeof value === "string" && functions.includes(value)) {
    return `${value}()`;
  } else {
    return `'${value}'`;
  }
}
