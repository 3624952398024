import { Model, ModelAttributes, loadReference, model, modelConfig } from "@mixitone/oom";
import AccountUser from "./AccountUser";
import ClubPlayer from "./ClubPlayer";
import Night from "./Night";
import { setAccountId } from "./concerns/SetAccountId";

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    account_user_id: {
      type: "string",
    },
    club_player_id: {
      type: "string",
    },
    created_at: {
      type: "datetime",
    },
    deferred_payment: {
      type: "boolean",
    },
    is_deleted: {
      type: "boolean",
    },
    night_id: {
      type: "string",
    },
    note: {
      type: "string",
    },
    resolved_at: {
      type: "datetime",
    },
    updated_at: {
      type: "datetime",
    },
  },
  {},
  {},
);

interface Note extends ModelAttributes<typeof config> {}

@setAccountId
@model("notes", config)
class Note extends Model<typeof config> {
  get clubPlayer() {
    return loadReference(ClubPlayer, this.club_player_id);
  }

  get accountUser() {
    return loadReference(AccountUser, this.account_user_id);
  }

  get night() {
    return loadReference(Night, this.night_id);
  }

  get resolved() {
    return !!this.resolved_at;
  }

  resolve() {
    this.resolved_at = new Date();
  }
}

export default Note;
