const wwwUrl = import.meta.env.VITE_WWW;

const Footer = () => {
  return (
    <footer
      className="border-scale-500 dark:border-scale-600 border-t bg-gray-50"
      aria-labelledby="footerHeading"
    >
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="container relative mx-auto px-6 py-6 sm:py-8 md:py-8 lg:px-10 lg:py-8 xl:px-10">
        <div>
          <ul className="flex items-center justify-center divide-x text-center">
            <li className="p-2">
              <a
                href="mailto:support@mixitone.com"
                className="text-scale-1100 hover:text-scale-1200 text-sm transition-colors "
              >
                <span className="hidden md:block">support@mixitone.com</span>
                <span className="block md:hidden">Support</span>
              </a>
            </li>
            <li className="p-2">
              <a
                href={`${wwwUrl}/terms`}
                className="text-scale-1100 hover:text-scale-1200 text-sm transition-colors "
              >
                <span className="hidden md:block">Terms and conditons</span>
                <span className="block md:hidden">Terms</span>
              </a>
            </li>
            <li className="p-2">
              <a
                href={`${wwwUrl}/privacy`}
                className="text-scale-1100 hover:text-scale-1200 text-sm transition-colors "
              >
                <span className="hidden md:block">Privacy policy</span>
                <span className="block md:hidden">Privacy</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="border-scale-500 dark:border-scale-600 border-t py-2 text-center">
        <small className="small">© MixItOne</small>
      </div>
    </footer>
  );
};

export default Footer;
