import { Spinner } from "./Spinner";

export const LoadingScreen: React.FC = () => {
  return (
    <div className="flex h-[50vh] w-full flex-row items-center">
      <div className="flex flex-col items-center w-full">
        <Spinner size={100} />
      </div>
    </div>
  );
};
