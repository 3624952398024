import { Model, ModelAttributes, model, modelConfig, reference } from "@mixitone/oom";
import ClubPlayer from "./ClubPlayer";
import Token from "./Token";
import { Loadable } from "@mixitone/oom/reference";

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    club_player_id: {
      type: "string",
    },
    created_at: {
      type: "datetime",
    },
    is_deleted: {
      type: "boolean",
    },
    token_id: {
      type: "string",
    },
    updated_at: {
      type: "datetime",
    },
  },
  {},
  {
    club_player: reference<any>(() => ClubPlayer as any, "club_player_id"),
    token: reference(() => Token, "token_id"),
  },
);

interface ClubPlayerToken extends ModelAttributes<typeof config> {
  clubPlayer: Loadable<ClubPlayer>;
  token: Loadable<Token>;
}

@model("club_player_tokens", config)
class ClubPlayerToken extends Model<typeof config> {}

export default ClubPlayerToken;
