const cacheMap = new WeakMap<
  object,
  Map<string | symbol, { promise: Promise<any>; timeoutId: NodeJS.Timeout }>
>();

export function withCachedPromise<T>(expiryDuration: number) {
  return function (
    target: any,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<(...args: any[]) => Promise<T>>,
  ): TypedPropertyDescriptor<(...args: any[]) => Promise<T>> | void {
    const originalMethod = descriptor.value!;
    const cacheKey = Symbol(propertyKey);

    descriptor.value = function (...args: any[]): Promise<T> {
      if (!cacheMap.has(this)) {
        cacheMap.set(this, new Map());
      }

      const instanceCache = cacheMap.get(this)!;

      if (!instanceCache.has(cacheKey)) {
        const promise = originalMethod.apply(this, args);
        const timeoutId = setTimeout(() => {
          instanceCache.delete(cacheKey);
        }, expiryDuration);

        instanceCache.set(cacheKey, { promise, timeoutId });
      }

      return instanceCache.get(cacheKey)!.promise;
    };

    return descriptor;
  };
}
