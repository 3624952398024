import { Spinner } from "@mixitone/components";
import App from "App";
import Tauri from "Tauri";
import clsx from "clsx";

import SessionController from "controllers/SessionController";
import { ApplicationView } from "@mixitone/mvc";
import tauri from "lib/tauri";

const Session: React.FC = () => {
  const controller = SessionController.use();
  const { loginState } = controller.state;

  if (tauri.enabled) {
    return <Tauri />;
  }

  if (loginState === "unknown") {
    return (
      <div className={clsx("h-screen bg-brand-600", { "sm:p-10": !tauri.enabled })}>
        <div
          className={clsx(
            "flex h-full w-full items-center justify-center bg-slate-800 bg-gradient-to-r from-slate-700 to-slate-800 sm:flex-col sm:bg-gradient-to-b",
            { "sm:rounded-lg": !tauri.enabled },
          )}
        >
          <Spinner size={128} className="fill-brand-600" />
        </div>
      </div>
    );
  }

  return <App />;
};

export default SessionController.scope(ApplicationView(Session));
