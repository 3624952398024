import { FloatingArrow, arrow, shift, size, useFloating } from "@floating-ui/react";
import React, { useRef, useState } from "react";
import { Button } from "./Button";
import { Dropdown } from "./Dropdown";

interface Props {
  className?: string;
  buttonComponent?:
    | React.ComponentType<{
        ref: any;
        className?: string;
        onClick: (...args: any[]) => void;
      }>
    | React.ElementType;
  button: React.ReactNode;
  buttonProps?: React.ComponentPropsWithoutRef<any>;
  buttonRef?: React.MutableRefObject<any> | ((ref: any) => void);
  dropdownTitle?: React.ReactNode;
  arrow?: boolean;
  children: React.ReactNode;
  role?: "tooltip" | "menu";
}

const DropdownButton: React.FC<Props> = ({
  className,
  buttonComponent,
  buttonProps,
  button: buttonText,
  buttonRef,
  dropdownTitle,
  arrow: showArrow,
  role,
  children,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [justClosed, setJustClosed] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const arrowRef = useRef(null);
  const { x, y, strategy, refs, context, update } = useFloating({
    middleware: [
      shift({
        padding: 16,
      }),
      arrow({ element: arrowRef }),
      size({
        apply({ availableHeight }) {
          setMaxHeight(availableHeight);
        },
        padding: 16,
      }),
    ],
  });
  const Component = buttonComponent ?? Button;

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (dropdownOpen || justClosed) return;
    setDropdownOpen(true);
    // Update the dropdown position in case the button moved
    update();
  };

  const handleClose = () => {
    setJustClosed(true);
    setDropdownOpen(false);
    setTimeout(() => setJustClosed(false), 200);
  };

  const button = React.createElement(
    Component,
    {
      ref: (ref: any) => {
        refs.setReference(ref);
        if (buttonRef) {
          if (typeof buttonRef === "function") {
            buttonRef(ref);
          } else {
            buttonRef.current = ref;
          }
        }
      },
      className: className,
      onClick: handleClick,
      ...buttonProps,
    },
    buttonText,
  );

  return (
    <>
      {button}
      {dropdownOpen && (
        <Dropdown
          dropRef={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            width: "max-content",
            minWidth: context.elements.reference?.getBoundingClientRect().width + "px",
            maxHeight: maxHeight + "px",
          }}
          className={showArrow ? "mt-2" : "overflow-auto"}
          open={dropdownOpen}
          onClose={handleClose}
          title={dropdownTitle}
          role={role ?? "menu"}
        >
          {showArrow && (
            <FloatingArrow ref={arrowRef} context={context} fill="white" className="fill-white" />
          )}
          {children}
        </Dropdown>
      )}
    </>
  );
};

export { DropdownButton };
