import { Field } from "..";
import { InsertQuery, Query, UpdateQuery } from "../query";

export type DataResponse<Data> = {
  data: Data[] | null;
  error?: { message: string };
};
export type SingleResponse<Data> = {
  data: Data | null;
  error?: { message: string };
};
export type CountResponse = {
  count: number | null;
  error?: { message: string } | null;
};

export abstract class DatabaseAdapter {
  abstract execute<Data>(query: Query<any, any>): Promise<DataResponse<Data>>;
  abstract count(query: Query<any, any>): Promise<CountResponse>;
  abstract insert<Data>(query: InsertQuery<any, any>, data: Record<string, any>): Promise<DataResponse<Data>>;
  abstract update<Data>(query: UpdateQuery<any, any>, data: Record<string, any>): Promise<DataResponse<Data>>;
  abstract delete(query: UpdateQuery<any, any>): Promise<CountResponse>;
  abstract convertType(field: Field, value: any): any;
}
