import { entries } from "@mixitone/util";

export type Filter = Record<string, string | string[]>;

export function matches(filter: Filter, data: any) {
  return entries(filter).every(([key, value]) => {
    if (Array.isArray(value)) {
      return value.includes(data[key]);
    } else {
      return value === data[key];
    }
  });
}
