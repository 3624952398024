import { Model, ModelAttributes, model, modelConfig, reference } from "@mixitone/oom";
import ClubPlayer from "./ClubPlayer";

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    club_player_id: {
      type: "string",
    },
    created_at: {
      type: "datetime",
    },
    is_deleted: {
      type: "boolean",
    },
    rated_at: {
      type: "datetime",
    },
    rating: {
      type: "number",
    },
    updated_at: {
      type: "datetime",
    },
  },
  {
    // Add collections here if needed
  },
  {
    // Add references here if needed
    club_player: reference<any>(() => ClubPlayer as any, "club_player_id"),
  },
);

interface PlayerRating extends ModelAttributes<typeof config> {}

@model("player_ratings", config)
class PlayerRating extends Model<typeof config> {}

export default PlayerRating;
