import { GameSet, Player } from "../index";
import { isPresent } from "@mixitone/util";
import Debug from "debug";
import { FillOptions, GameFiller } from "./GameFiller";

const debug = Debug("GameSetFiller");

export class GameSetFiller {
  players: Player[];
  playerPairs: Record<string, string>;
  playersInPreviousGame: string[];
  playersNotInPreviousGame: string[];
  playerGroups: Record<string, string>;

  constructor(private gameSet: GameSet) {
    this.players = (this.gameSet.night.players as Player[]).filter((player) => player.active);
    this.playerPairs = this.players.reduce(
      (acc, player) => {
        if (player.partner_id) {
          acc[player.id!] = player.partner_id;
        }
        return acc;
      },
      {} as Record<string, string>,
    );
    // Get players that played in the last game
    this.playersInPreviousGame =
      this.night.game_sets[this.gameSet.index! - 1]?.playerIds().filter(isPresent) || [];
    this.playersNotInPreviousGame = this.players
      .filter((player) => !this.playersInPreviousGame.includes(player.id!))
      .map((p) => p.id!);
    this.playerGroups = this.players.reduce(
      (acc, player) => {
        const { id, group } = player;
        if (!id || !group) return acc;
        const groupId = group.id;
        if (!groupId) return acc;
        return { ...acc, [id]: groupId };
      },
      {} as Record<string, string>,
    );
  }

  get night() {
    return this.gameSet.night;
  }
  get club() {
    return this.gameSet.club;
  }

  groupName(id: string) {
    return this.night.club.groups.find((g) => g.id === id)?.name;
  }

  playerName(id: string) {
    return this.players.find((p) => p.id === id)?.name;
  }

  createGroupedPlayers(players: Player[]) {
    return players.reduce(
      (acc, player) => {
        const { id, group } = player;
        if (!id || !group) return acc;
        const groupId = group.id;
        if (!groupId) return acc;
        return { ...acc, [groupId]: [...(acc[groupId] || []), id] };
      },
      {} as Record<string, string[]>,
    );
  }

  playerInLastGame(playerId: string) {
    return this.playersInPreviousGame.includes(playerId);
  }
  playerNotInLastGame(playerId: string) {
    // If this is the first set then this doesn't make sense
    if (this.gameSet.index === 0) return false;
    return !this.playerInLastGame(playerId);
  }

  get playersToPlace() {
    const playerIdsToPlace = this.gameSet.playerIds().reduce(
      (acc, id) => {
        acc.delete(id);
        return acc;
      },
      new Set(this.players.map((p) => p.id)),
    );
    return this.players.filter((player) => playerIdsToPlace.has(player.id));
  }

  gameIsBalanced(playerIds: string[]) {
    const groupCounts = playerIds
      .map((p) => this.playerGroups[p])
      .reduce<Record<string, number>>((counts, group) => {
        counts[group] = (counts[group] || 0) + 1;
        return counts;
      }, {});
    return Object.values(groupCounts).every((count) => count % 2 === 0);
  }

  fill() {
    const unlockedGames = this.gameSet.unlockedGames;
    const groupExclusions = this.club.groupExclusions;
    Object.keys(groupExclusions).forEach((group) => {
      console.log(
        "group",
        this.groupName(group),
        "excludes",
        groupExclusions[group].map(this.groupName.bind(this)),
      );
    });

    const groupedPlayersNotInPreviousGames = this.createGroupedPlayers(
      this.playersToPlace.filter((p) => this.playerNotInLastGame(p.id!)),
    );

    const options: FillOptions = {
      playerName: (id: string) => this.playerName(id),
      groupName: (id: string) => this.groupName(id),
      groupedPlayers: groupedPlayersNotInPreviousGames,
      playerGroups: this.playerGroups,
      groupExclusions,
      playerPairs: this.playerPairs,
      playersNotInPreviousGames: this.playersNotInPreviousGame,
    };

    if (this.playersNotInPreviousGame.length > 3) {
      // Fill games with players that have not played in the last game only
      unlockedGames
        .slice()
        .sort((a, b) => b.playerCount - a.playerCount)
        .forEach((game) => {
          const filler = new GameFiller(game, options);
          filler.fill();
        });
    }

    const groupedPlayers = this.createGroupedPlayers(this.playersToPlace);
    options.groupedPlayers = groupedPlayers;
    // Fill games with players
    unlockedGames
      .slice()
      .sort((a, b) => b.playerCount - a.playerCount)
      .forEach((game) => {
        const filler = new GameFiller(game, options);
        filler.fill();
      });

    const outPlayerIds = Object.values(groupedPlayers).flat();
    const outPlayers = this.players.filter((p) => outPlayerIds.includes(p.id!));
    const inPlayers = this.players.filter((p) => !outPlayerIds.includes(p.id!));

    const timesOut = this.players.reduce(
      (acc, player) => ({
        [player.id!]: this.night.game_sets.reduce(
          (acc, set, idx) => (idx < this.gameSet.index! && !set.hasPlayer(player.id!) ? acc + 1 : acc),
          0,
        ),
        ...acc,
      }),
      {} as Record<string, number>,
    );

    // Sort them by timesOut
    outPlayers.sort((a, b) => timesOut[a.id!] - timesOut[b.id!]);
    inPlayers.sort((a, b) => timesOut[a.id!] - timesOut[b.id!]);

    // Select the outPlayers who have been out more than any inPlayer
    const outPlayersToSwap = outPlayers
      .filter(
        (outPlayer) =>
          this.playerNotInLastGame(outPlayer.id!) ||
          inPlayers.some((inPlayer) => timesOut[inPlayer.id!] < timesOut[outPlayer.id!]),
      )
      .map((p) => p.id!);

    const canPlayPlayers = (player: string, canPlay: string[]) => {
      const playerGroup = this.playerGroups[player];
      const canPlayGroups = [...new Set(canPlay.map((player) => this.playerGroups[player]))];
      const excludedGroups = new Set(canPlayGroups.map((group) => groupExclusions[group]).flat());
      return !excludedGroups.has(playerGroup);
    };

    const remainingOutPlayers = outPlayersToSwap.slice();

    const swapPlayer = (inPlayer: string, swapWith: string) => {
      unlockedGames.find((g) => g.hasPlayer(inPlayer))?.swapPlayer(inPlayer, swapWith);
      remainingOutPlayers.splice(remainingOutPlayers.indexOf(swapWith), 1);
    };

    while (outPlayersToSwap.length > 0) {
      let outPlayers: string[] = [];
      const outPlayer = outPlayersToSwap.shift()!;
      debug("trying to swap in", this.playerName(outPlayer));
      const partner = this.playerPairs[outPlayer];
      if (partner) {
        debug("they have a partner", this.playerName(partner));
        if (outPlayersToSwap.includes(partner)) {
          debug("who we can include");
          outPlayersToSwap.splice(outPlayersToSwap.indexOf(partner), 1);
          outPlayers = [outPlayer, partner];
        } else {
          debug("who we can't include");
          // we can't swap in one partner
          continue;
        }
      } else {
        outPlayers = [outPlayer];
      }

      const notPlayedLastGame = outPlayers.some((p) => this.playerNotInLastGame(p));

      for (const game of unlockedGames) {
        const playerIds = game.activePlayerIds();
        // Get all the players that have played more than the outPlayer
        // OR where the outPlayer has not played and the player has played in
        // the last game
        const playersCanSwapWith = playerIds.filter(
          (p) => (notPlayedLastGame && this.playerInLastGame(p)) || timesOut[p] < timesOut[outPlayer],
        );
        // If there are not enough players to swap with then continue
        if (playersCanSwapWith.length < outPlayers.length) {
          debug("nobody to swap with in game", game.index);

          playerIds.forEach((p) => {
            try {
              debug(this.playerName(p), "out", timesOut[p], "times, against", timesOut[outPlayer], "times");
            } catch (e) {
              console.error(e);
            }
          });

          continue;
        }
        // Sort the players by the number of times they have been out
        playersCanSwapWith.sort((a, b) => timesOut[a] - timesOut[b]);
        // We'll fill this array with the players we'll swap with from
        // playersCanSwapWith plus their partners if they have any
        let playersIWillSwapWith: string[] = [];

        while (playersIWillSwapWith.length < outPlayers.length) {
          const nextPlayer = playersCanSwapWith.shift()!;
          if (!nextPlayer) break;
          const partner = this.playerPairs[nextPlayer];
          if (partner) {
            debug("player", this.playerName(nextPlayer), "has a partner", this.playerName(partner));
            // If the player has a partner and the partner can be swapped with too then add them
            // to the list, but if they cannot be swapped OR we end up with too many players to swap
            // then don't try the swap at all
            if (playersCanSwapWith.includes(partner)) {
              debug("partner can be swapped with");
              playersCanSwapWith.splice(playersCanSwapWith.indexOf(partner), 1);

              if (playersIWillSwapWith.length + 2 > outPlayers.length) {
                debug("but that resulted in too many players to swap");
                continue;
              }

              playersIWillSwapWith.push(nextPlayer);
              playersIWillSwapWith.push(partner);
            } else {
              debug("partner can't be swapped with");
              continue;
            }
          } else {
            // debug("player", this.playerName(nextPlayer), "does not have a partner");
            playersIWillSwapWith.push(nextPlayer);
          }
        }

        // We need to double check that we have the right number of players to swap with
        if (playersIWillSwapWith.length < outPlayers.length) {
          debug("not enough players to swap with during double check");
          continue;
        }
        playersIWillSwapWith = playersIWillSwapWith.slice(0, outPlayers.length);

        // Now we need to check that the group exclusions are not violated by
        // swapping these players
        const playersLeft = playerIds.filter((p) => !playersIWillSwapWith.includes(p));

        if (!outPlayers.every((p) => canPlayPlayers(p, playersLeft))) {
          debug(
            "swapping",
            [this.playerName(outPlayers[0]), this.playerName(playersIWillSwapWith[0])],
            "would violate group exclusions",
          );
          continue;
        }

        // Now check if this swap will lead to an unbalanced game
        if (this.gameIsBalanced(playerIds)) {
          if (!this.gameIsBalanced([...outPlayers, ...playersLeft])) {
            debug(
              "swapping",
              [this.playerName(outPlayers[0]), this.playerName(playersIWillSwapWith[0])],
              "would lead to an unbalanced game:",
              [...outPlayers, ...playersLeft].map((name) => this.playerName(name)),
            );
            continue;
          }
        }

        // Perform the swap and break out of the game check loop
        for (const [index, outPlayer] of outPlayers.entries()) {
          debug("swapping", this.playerName(outPlayer), "with", this.playerName(playersIWillSwapWith[index]));
          swapPlayer(playersIWillSwapWith[index], outPlayer);
        }
        break;
      }
    }

    const playerThatCanPlayPlayers = (players: string[], otherPlayers: string[]) => {
      for (let i = 0; i < players.length; i++) {
        for (let j = i + 1; j < players.length; j++) {
          // Check if both players[i] and players[j] can play with otherPlayers
          if (canPlayPlayers(players[i], otherPlayers) && canPlayPlayers(players[j], otherPlayers)) {
            return [players[i], players[j]];
          }
        }
      }
    };

    if (remainingOutPlayers.length > 1) {
      debug("trying to swap with pairs");
      // Assuming that we have done all the individual swaps possible, see if there are pair swaps possible
      const playerIds = this.gameSet.activePlayerIds();

      const validPairs = playerIds
        .filter((p) => this.playerPairs[p])
        .reduce(
          (acc, item, idx, array) => {
            const partner = this.playerPairs[item];
            // Check if the pair exists in the map and in the array, and if it hasn't been added to 'seen'
            if (partner && array.includes(partner) && !acc.seen.has(item) && !acc.seen.has(partner)) {
              acc.result.push([item, partner]);
              acc.seen.add(item);
              acc.seen.add(partner);
            }
            return acc;
          },
          { result: [] as Array<string[]>, seen: new Set() },
        ).result;

      for (const pair of validPairs) {
        if (remainingOutPlayers.length < 2) break;
        const game = this.gameSet.playerGame(pair[0]);
        if (!game) continue;
        const otherPlayers = game.activePlayerIds().filter((p) => !pair.includes(p));
        // find 2 items in remainingOutPlayers that satisfy canPlay
        const players = playerThatCanPlayPlayers(remainingOutPlayers, otherPlayers);
        if (players) {
          debug("swapping", this.playerName(players[0]), "with", this.playerName(pair[0]));
          debug("swapping", this.playerName(players[0]), "with", this.playerName(pair[1]));
          swapPlayer(pair[0], players[0]);
          swapPlayer(pair[1], players[1]);
          break;
        } else {
          debug("no swaps found for", this.playerName(pair[0]), this.playerName(pair[1]));
        }
      }
    }
  }
}
