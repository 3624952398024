type ExtractRouteParams<T extends string> = string extends T
  ? Record<string, string>
  : T extends `${infer Start}:${infer Param}/${infer Rest}`
  ? {
      [k in Param | keyof ExtractRouteParams<Rest>]: string;
    }
  : T extends `${infer Start}:${infer Param}`
  ? {
      [k in Param]: string;
    }
  : {};

interface Route<S extends string> {
  path: string;
  action: (path: string, segments: ExtractRouteParams<S>) => boolean;
}

/**
 * A simple router that matches paths to actions.
 *
 * Example:
 *
 * const router = new RouteMatcher();
 * router.on("/foo/:bar", (path, segments) => {
 *   console.log(segments.bar);
 *   return true;
 * });
 * router.match("/foo/baz");
 */
export class RouteMatcher {
  routes: Route<any>[];

  constructor() {
    this.routes = [];
  }

  on<S extends string>(
    path: S,
    action: (path: string, segments: ExtractRouteParams<S>) => boolean
  ) {
    this.routes.push({ path, action } as Route<S>);
  }

  match(path: string, partial: boolean = false) {
    for (const route of this.routes) {
      const segments = this.matchPath(path, route.path, partial);
      if (segments) {
        if (!route.action(segments[0], segments[1])) {
          return;
        }

        if (!partial) {
          return;
        }
      }
    }
  }

  matchPath<S extends string>(
    path: string,
    pattern: S,
    partial: boolean = false
  ): [string, ExtractRouteParams<S>] | null {
    const pathSegments = path.split("/").filter(Boolean);
    const patternSegments = pattern.split("/").filter(Boolean);

    if (!partial && pathSegments.length !== patternSegments.length) {
      return null;
    }

    const minLength = Math.min(pathSegments.length, patternSegments.length);
    const segments: any = {};

    for (let i = 0; i < minLength; i++) {
      if (patternSegments[i].startsWith(":")) {
        segments[patternSegments[i].slice(1)] = pathSegments[i];
      } else if (pathSegments[i] !== patternSegments[i]) {
        return null;
      }
    }

    if (partial && pathSegments.length < patternSegments.length) {
      return null;
    }

    return [pathSegments.slice(0, patternSegments.length).join("/"), segments];
  }
}
