import { Button, ButtonKind } from "./Button";
import { Modal } from "./Modal";

interface Props {
  title: string;
  message: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  confirmKind?: ButtonKind;
  confirmText: string | null;
  cancelKind?: ButtonKind;
  cancelText: string | null;
  loading?: boolean;
}

/**
 * Uses <Modal> to alert the user and ask are you sure
 */
export const ModalAlert: React.FC<Props> = ({
  title,
  message,
  onConfirm,
  onCancel,
  confirmKind,
  confirmText,
  cancelKind,
  cancelText,
  loading,
}) => {
  return (
    <Modal title={title} onClose={onCancel}>
      <div className="flex flex-col items-center space-y-4">
        <p>{message}</p>
        <div className="flex justify-center space-x-2">
          {confirmText && (
            <Button kind={confirmKind} onClick={onConfirm} disabled={loading} spinner={loading}>
              {confirmText}
            </Button>
          )}
          {cancelText && (
            <Button kind={cancelKind} onClick={onCancel} disabled={loading}>
              {cancelText}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
