import {
  DropdownButton,
  DropdownMenuItem,
  DropdownMenuSeparator,
  ModalAlert,
  Pulse,
} from "@mixitone/components";
import UserIcon from "@mixitone/components/icons/user.svg";
import { ApplicationView } from "@mixitone/mvc";
import clsx from "clsx";
import { AppController } from "controllers/AppController";
import { routeHelpers } from "controllers/routes";
import tauri from "lib/tauri";
import { useCallback, useState } from "react";

const UserMenu = () => {
  const appController = AppController.use();
  const { updateAvailable } = appController.state;
  const [showSignOutModal, setShowSignOutModal] = useState(false);

  const handleClickLogout = useCallback(() => {
    setShowSignOutModal(true);
  }, []);

  const handleLogout = useCallback(() => {
    appController.actionSignOut();
  }, [appController]);

  const handleUpdater = useCallback(() => {
    appController.actionCheckForUpdates();
  }, [appController]);

  return (
    <>
      <DropdownButton
        className="user-menu"
        buttonComponent="button"
        button={
          <div className="relative">
            <Pulse
              className={clsx({
                "opacity-0": !updateAvailable,
                "opacity-100": updateAvailable,
              })}
            />
            <UserIcon className="w-[34px] stroke-white hover:stroke-slate-300" />
          </div>
        }
        dropdownTitle="Signed in"
        arrow
      >
        <DropdownMenuItem value="profile" href={routeHelpers.profile({})}>
          My profile
        </DropdownMenuItem>
        <DropdownMenuItem value="account" href={routeHelpers.account({})}>
          Account settings
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        {tauri.enabled && (
          <DropdownMenuItem
            value="updater"
            onClick={handleUpdater}
            closeOnClick
            className="flex items-center gap-2"
          >
            <div>{updateAvailable ? "New version available" : "Check for updates"}</div>
            {updateAvailable && <Pulse className="relative mt-0" />}
          </DropdownMenuItem>
        )}
        <DropdownMenuSeparator />
        <DropdownMenuItem value="signOut" onClick={handleClickLogout} closeOnClick>
          Sign out
        </DropdownMenuItem>
      </DropdownButton>
      {showSignOutModal && (
        <ModalAlert
          message={
            <section>
              <p>Are you sure you want to sign out?</p>
              {tauri.enabled && <p>You need to be connected to the internet to sign back in.</p>}
            </section>
          }
          onConfirm={handleLogout}
          title={"Sign out"}
          onCancel={() => {
            setShowSignOutModal(false);
          }}
          confirmText={"Sign out"}
          confirmKind="danger"
          cancelText={"Stay signed in"}
        />
      )}
    </>
  );
};

export default ApplicationView(UserMenu);
