import { arraySwap, isNil, isPresent, shuffle } from "@mixitone/util";
import Game from "../Game";
import Debug from "debug";

const debug = Debug("GameFiller");

export interface FillOptions {
  playerName(id: string): string | undefined;
  groupName(id: string): string | undefined;
  /** key is group id, value is array of player ids */
  groupedPlayers: Record<string, string[]>;
  /** key is player id, value is group id  */
  playerGroups: Record<string, string>;
  /** key is group id, value is array of group ids that should not be matched with */
  groupExclusions: Record<string, string[]>;
  /**  */
  playerPairs: Record<string, string>;
  /**  */
  playersNotInPreviousGames: string[];
}

export class GameFiller {
  game: Game;
  options: FillOptions;
  playersInFlight: string[] = [];

  constructor(game: Game, options: FillOptions) {
    this.game = game;
    this.options = options;
  }

  playerName(id: string): string | undefined {
    return this.options.playerName(id);
  }

  groupName(id: string): string | undefined {
    return this.options.groupName(id);
  }

  get groupedPlayers(): Record<string, string[]> {
    return this.options.groupedPlayers;
  }
  get playerGroups(): Record<string, string> {
    return this.options.playerGroups;
  }
  get groupExclusions(): Record<string, string[]> {
    return this.options.groupExclusions;
  }
  get playerPairs(): Record<string, string> {
    return this.options.playerPairs;
  }
  get playersNotInPreviousGames(): string[] {
    return this.options.playersNotInPreviousGames;
  }

  groupPlayerCount(group: string): { singles: number; pairs: number; length: number } {
    const players = this.groupedPlayers[group];
    const countedPlayers = new Set();
    let singles = 0;
    let pairs = 0;

    players.forEach((player) => {
      if (countedPlayers.has(player)) return;
      const partner = this.playerPairs[player];
      if (partner) {
        countedPlayers.add(partner);
        pairs++;
      } else {
        singles++;
      }
    });

    return {
      length: players.length,
      singles: singles,
      pairs: pairs,
    };
  }

  groupsWithNPlayers(n: number) {
    return Object.keys(this.groupedPlayers).filter((key) => {
      const count = this.groupPlayerCount(key);
      if (count.singles >= n) return true;
      if (n % 2 === 0) {
        return count.pairs * 2 + count.singles >= n;
      }
      const leftOver = n - count.pairs * 2;
      return count.singles >= leftOver;
    });
  }

  suitableGroups(n: number, options: { group?: string; canPlay?: string[] } = {}): string[] {
    const { group, canPlay } = options;
    let suitableGroups = this.groupsWithNPlayers(n).filter((key) => isNil(group) || key === group);

    if (isPresent(canPlay)) {
      const canPlayGroups = [...new Set(canPlay.map((player) => this.playerGroups[player]))];
      debug("suitable groups must be able to play with", canPlayGroups.map(this.groupName.bind(this)));

      const excludedGroups = new Set(canPlayGroups.map((group) => this.groupExclusions[group]).flat());
      suitableGroups = suitableGroups.filter((group) => !excludedGroups.has(group));
    }

    return suitableGroups;
  }

  /**
   * Pop n players from the groupedPlayers. All players will be from the same
   * group unless a player id is specified and they have a partner in a
   * different group, in which case you get both players regardless of group.
   *
   * @param n number of players to pop
   * @param options
   * @param options.playerId player id to pop
   * @param options.group group id to pop from
   * @param options.canPlay array of player ids the popped players can play with in regards to groupExclusions
   */
  popNPlayers(n: number, options: { playerId?: string; group?: string; canPlay?: string[] } = {}) {
    const { playerId, group, canPlay } = options;

    if (n > 1 && isPresent(playerId))
      throw new Error("Cannot pop multiple players with a specific player id");

    if (isPresent(playerId)) {
      const playerGroupId = this.playerGroups[playerId];
      if (isNil(playerGroupId)) return [];
      if (group && playerGroupId !== group) return [];
      if (canPlay && !canPlay.includes(playerId)) return [];
      const playerGroup = this.groupedPlayers[playerGroupId];
      if (isNil(playerGroup)) {
        return [];
      }
      const playerIndex = playerGroup.indexOf(playerId);
      if (playerIndex === -1) return [];
      const partner = this.playerPairs[playerId];
      if (partner) {
        const partnerGroup = this.groupedPlayers[this.playerGroups[partner]];
        const partnerIndex = partnerGroup.indexOf(partner);
        if (partnerIndex === -1) return [];
        playerGroup.splice(playerIndex, 1);
        partnerGroup.splice(partnerIndex, 1);
        return [playerId, partner];
      }

      playerGroup.splice(playerIndex, 1);
      return [playerId];
    }

    const suitableGroups = shuffle(this.suitableGroups(n, options));

    while (true) {
      const suitableGroup = suitableGroups.pop();
      if (!suitableGroup) break;

      let groupPlayers = shuffle(this.groupedPlayers[suitableGroup]);

      // Select the first n players from the sorted list
      let returnPlayers = groupPlayers.slice(0, n);

      let i = 0;
      while (true) {
        let p = returnPlayers[i];
        if (!p) break;
        let partner = this.playerPairs[p];
        if (!partner) {
          i++;
          continue;
        }
        const partnerIndex = returnPlayers.indexOf(partner);
        if (partnerIndex === -1) {
          if (i + 1 >= n) {
            // Either replace the player with one who does not have a partner or abort
            const replacement = groupPlayers
              .reverse()
              .find((p) => !returnPlayers.includes(p) && !this.playerPairs[p]);
            if (!replacement) {
              returnPlayers = [];
              break;
            }
            returnPlayers[i] = replacement;
            break;
          } else {
            // Partner is in a different group, bring them in
            returnPlayers[i + 1] = partner;
            i += 2;
            continue;
          }
        } else {
          // Partner is in the same group, move them up
          arraySwap(returnPlayers, i + 1, partnerIndex);
          i += 2;
        }
      }
      if (returnPlayers.length === 0) continue;
      if (returnPlayers.length !== n) {
        debug("failed to find n players", returnPlayers);
        continue;
      }

      // Remove the selected players from their groups
      returnPlayers.forEach((player) => {
        this.groupedPlayers[this.playerGroups[player]].splice(
          this.groupedPlayers[this.playerGroups[player]].indexOf(player),
          1,
        );
      });

      return returnPlayers;
    }

    return [];
  }

  /**
   * Push players back to available groups
   */
  pushPlayers(players: string[]) {
    players.forEach((player) => {
      this.groupedPlayers[this.playerGroups[player]].push(player);
    });
  }

  fill(): boolean {
    if (this.game.full()) return true;

    debug(
      "Filling",
      this.game.index,
      this.game.playerCount,
      this.game.playerIds.map((id) => this.playerName(id!)),
    );

    if (this.game.playerCount === 3) {
      this.fill3();
    }
    if (this.game.playerCount === 2) {
      this.fill2();
    }
    if (this.game.playerCount === 1) {
      this.fill1();
    }
    if (this.game.playerCount === 0) {
      this.fill0();
    }

    debug(
      "Filled",
      this.game.index,
      this.game.playerCount,
      this.game.playerIds.map((id) => this.playerName(id!)),
    );
    return this.game.valid();
  }

  private fill3() {
    const firstThree = this.game.playerIds.filter(isPresent) as string[];
    // Check if all existing players in this game have the same group
    const gamePlayerGroups = firstThree.map((id) => this.playerGroups[id as string]);
    const allSameGroup = gamePlayerGroups.every((group) => group === gamePlayerGroups[0]);

    if (allSameGroup) {
      // Find a final player in groupPlayers with that group. We do not do
      // exclusion here because that must have already been manually
      // overridden
      const group = gamePlayerGroups[0];
      const players = this.popNPlayers(1, { group });
      if (players.length === 1) {
        debug("found for same group");
        return this.game.addPlayers(players);
      }
    }

    // Find the group with only one player in the game
    const singlePlayerGroup = gamePlayerGroups.find(
      (group) => gamePlayerGroups.filter((g) => g === group).length === 1,
    );

    if (singlePlayerGroup) {
      // Find a player in groupedPlayers with the same group as singlePlayerGroup
      const players = this.popNPlayers(1, {
        group: singlePlayerGroup,
        canPlay: firstThree,
      });
      if (players.length === 1) {
        debug("found for single player group");
        return this.game.addPlayers(players);
      }
    }

    // Add any player to the group
    const players = this.popNPlayers(1, { canPlay: firstThree });
    if (players.length === 1) {
      debug("found random");
      return this.game.addPlayers(players);
    }

    return false;
  }

  private fill2() {
    const firstTwo = this.game.activePlayerIds();

    // If the players are different groups then try finding a balanced pair
    if (this.playerGroups[firstTwo[0]] !== this.playerGroups[firstTwo[1]]) {
      const secondTwo = [
        ...this.popNPlayers(1, {
          group: this.playerGroups[firstTwo[0]],
        }),
        ...this.popNPlayers(1, {
          group: this.playerGroups[firstTwo[1]],
        }),
      ];

      if (secondTwo.length === 2) {
        debug("found different group balanced");
        return this.game.addPlayers(secondTwo);
      } else {
        // Couldn't find another 2 that could play so push back
        debug("failed to find different group balanced");
        this.pushPlayers(secondTwo);
      }
    }

    // Try grabbing from one group / pairs
    const players = this.popNPlayers(2, { canPlay: firstTwo });
    if (players.length === 2) {
      debug("found same group");
      return this.game.addPlayers(players);
    } else {
      debug("failed to find same group");
      this.pushPlayers(players);
    }

    // Assign the next available players from any groups
    const suitableGroups = this.suitableGroups(1, { canPlay: firstTwo });

    for (const group of suitableGroups) {
      const [player_1] = this.popNPlayers(1, { group, canPlay: firstTwo });
      const [player_2] = this.popNPlayers(1, { canPlay: [player_1, ...firstTwo] });

      if (player_1 && player_2) {
        debug("fill 2 found 2 suitable players");
        return this.game.addPlayers([player_1, player_2]);
      } else {
        debug("fill 2 failed to find 2 suitable players");
        if (player_1) this.pushPlayers([player_1]);
        if (player_2) this.pushPlayers([player_2]);
      }
    }
  }

  private fill1() {
    // In this branch, the game already has 1 player assigned
    const firstPlayer = this.game.activePlayerIds();
    // Find another player with the same group as the existing player
    const existingPlayerGroup = this.playerGroups[firstPlayer[0]];
    let players = this.popNPlayers(1, { group: existingPlayerGroup });
    if (players.length > 0 && players.length < 4) {
      // Now delegate to fill(2 or 3)
      this.game.addPlayers(players);
      if (this.fill()) return;
      this.game.removePlayers(players);
      this.pushPlayers(players);
    }

    // We couldn't find another player with the same group as the existing player
    const otherPlayer = this.popNPlayers(1, { canPlay: firstPlayer });
    if (otherPlayer.length === 1) {
      // Now delegate to fill(2)
      this.game.addPlayers(otherPlayer);
      if (this.fill()) return;
      this.game.removePlayers(otherPlayer);
      this.pushPlayers(otherPlayer);
    }
  }

  private fill0() {
    if (this.playersNotInPreviousGames.length > 0) {
      const playerId = this.playersNotInPreviousGames.pop()!;
      const players = this.popNPlayers(1, { playerId });
      if (players.length > 0) {
        this.game.addPlayers(players);
        this.fill();

        if (this.game.full()) {
          return;
        } else {
          const players = this.game.activePlayerIds();
          this.game.removePlayers(players);
          this.pushPlayers(players);
        }
      } else {
        this.playersNotInPreviousGames.push(playerId);
        this.pushPlayers(players);
      }
    }

    const firstTwo = this.popNPlayers(2);

    if (firstTwo.length === 2) {
      // Let fill(2) do the rest
      this.game.addPlayers(firstTwo);
      debug("fill for 0 found 2 players same group");

      this.fill();
      if (this.game.playerCount === 2) {
        debug("failed to find balanced players");
        this.game.clear();
        this.pushPlayers(firstTwo);
      } else {
        return;
      }
    }

    // We couldn't find a good balance so lets do it randomly
    const players = this.popNPlayers(1);

    if (players.length === 1) {
      this.game.addPlayers(players);
      // Now let fill(1) do the rest
      if (this.fill()) return;

      this.game.removePlayers(players);
      this.pushPlayers(players);
    }
  }
}
