import { Collection, CollectionDescriptor, Collections } from "./collection";
import { Loadable, ReferenceDescriptor, References } from "./reference";

interface StringField {
  type: "string";
}
interface NumberField {
  type: "number";
}

interface NumberArrayField {
  type: "number[]";
}
interface StringArrayField {
  type: "string[]";
}
interface BooleanField {
  type: "boolean";
}
interface DatetimeField {
  type: "datetime";
}
interface EnumField<T> {
  type: "enum";
  enumType: T;
}

interface JsonField<T extends {}> {
  type: "json";
  jsonType: T;
}

export type Field =
  | StringField
  | StringArrayField
  | NumberField
  | NumberArrayField
  | BooleanField
  | DatetimeField
  | EnumField<any>
  | JsonField<any>;

export interface Fields {
  [index: string]: Field;
}

/**
 * The property field type on the model
 */
type GetFieldType<F extends Field> = F extends StringField
  ? string
  : F extends StringArrayField
    ? string[]
    : F extends NumberField
      ? number
      : F extends NumberArrayField
        ? number[]
        : F extends BooleanField
          ? boolean
          : F extends DatetimeField
            ? Date
            : F extends EnumField<infer T>
              ? T
              : F extends JsonField<infer T>
                ? T
                : never;

/**
 * The allowed input type when assigning to the model constructor attributes
 */
type GetAssignableFieldType<F extends Field> = F extends StringField
  ? string
  : F extends NumberField
    ? number
    : F extends NumberArrayField
      ? number[]
      : F extends BooleanField
        ? boolean
        : F extends DatetimeField
          ? string
          : F extends EnumField<infer T>
            ? T
            : F extends JsonField<infer T>
              ? T
              : never;

export type AnyModelConfig = ModelConfig<any, any, any>;

export type GetConfigFields<C extends AnyModelConfig> = C extends ModelConfig<infer F, any, any> ? F : never;

type GetConfigCollections<C extends ModelConfig<any, any, any>> =
  C extends ModelConfig<any, infer F, any> ? F : never;

export type Attributes<C extends ModelConfig<any, any, any>> = {
  [P in keyof GetConfigFields<C>]?: any | undefined | null;
};

export type AssignableModelFields<C extends ModelConfig<any, any, any>> = {
  [P in keyof GetConfigFields<C>]?: GetAssignableFieldType<GetConfigFields<C>[P]> | null | undefined;
};

export type ModelFields<C extends AnyModelConfig> = {
  [P in keyof GetConfigFields<C>]?: GetFieldType<GetConfigFields<C>[P]>;
};

export type ModelFieldsWithId<C extends AnyModelConfig> = ModelFields<C> & {
  id: string;
};

type GetCollectionType<C extends CollectionDescriptor<any>> =
  C extends CollectionDescriptor<infer M> ? Collection<M> : never;

export type ModelCollections<C extends AnyModelConfig> = {
  [P in keyof GetConfigCollections<C>]: GetCollectionType<GetConfigCollections<C>[P]>;
};

type GetReferenceType<R extends ReferenceDescriptor<any>> =
  R extends ReferenceDescriptor<infer M> ? Loadable<M> : never;

type GetConfigReferences<C extends AnyModelConfig> = C extends ModelConfig<any, any, infer R> ? R : never;

export type ModelReferences<C extends AnyModelConfig> = {
  [P in keyof GetConfigReferences<C>]: GetReferenceType<GetConfigReferences<C>[P]>;
};

export type ModelAttributes<C extends AnyModelConfig> = ModelFields<C> &
  ModelCollections<C> &
  ModelReferences<C>;

export type ModelConfig<F extends Fields, C extends Collections, R extends References> = {
  fields: F;
  collections: C;
  references: R;
};

export function modelConfig<F extends Fields, C extends Collections, R extends References>(
  fields: F,
  collections: C,
  references: R,
): ModelConfig<F, C, R> {
  return { fields, collections, references };
}
