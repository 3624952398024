import { Model, ModelAttributes, collection, loadReference, model, modelConfig } from "@mixitone/oom";
import { isNil, isPresent } from "@mixitone/util";
import dayjs from "dayjs";
import Debug from "debug";
import Club from "./Club";
import Game from "./Game";
import Night from "./Night";
import { PlayerContainer } from "./Player";
import { GameSetFiller } from "./concerns/GameSetFiller";
import { setAccountId } from "./concerns/SetAccountId";

const debug = Debug("oom:model:GameSet");

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    filled: {
      type: "boolean",
    },
    game_set_time: {
      type: "number",
    },
    index: {
      type: "number",
    },
    is_deleted: {
      type: "boolean",
    },
    night_id: {
      type: "string",
    },
    started_at: {
      type: "datetime",
    },
    stopped_at: {
      type: "datetime",
    },
    updated_at: {
      type: "datetime",
    },
    user_modified: {
      type: "boolean",
    },
  },
  {
    games: collection<Game>(() => Game, "game_set_id", { order: "index" }),
  },
  {},
);

interface GameSet extends ModelAttributes<typeof config> {}

@setAccountId
@model("game_sets", config)
class GameSet extends Model<typeof config> {
  static override tableName: "game_sets" = "game_sets";
  static override config = config;

  undoStack: Array<{ filled: boolean; user_modified: boolean }> = [];

  get night(): Night {
    return loadReference(Night, this.night_id)!;
  }
  get club(): Club {
    return this.night.club;
  }

  async ensureGames(courts: number) {
    const id = this.id;
    if (!id) throw new Error("Invalid set");

    await this.games.load();
    const games = this.games;

    if (games.length > courts) {
      debug(`need to delete ${games.length - courts} games`);
      const gamesToDelete = games.slice(courts);
      await Promise.all(gamesToDelete.map((game) => game.destroy()));
      games.splice(courts, games.length - courts);
    } else if (games.length < courts) {
      debug(`need to add ${courts - games.length} games`);
      const newGames = [...Array(courts - games.length)].map(() => new Game({ game_set_id: id, index: 0 }));

      games.push(...newGames);
    }

    await Promise.all(
      games.map((game, index) => {
        game.index = index;
        return game.save();
      }),
    );
  }

  get unlockedGames() {
    return this.games.filter((game) => !game.locked);
  }

  get canClear() {
    return this.unlockedGames.some((game) => game.playerCount > 0);
  }

  clear() {
    this.withUndo(() => {
      this.unlockedGames.forEach((game) => game.clear());
      this.filled = false;
      this.user_modified = false;
    });
  }

  clearUndo() {
    this.undoStack = [];
    this.games.forEach((game) => game.clearUndo());
  }

  get isLastStartedSet() {
    return this.night.game_sets.every((set) => set.index! <= this.index! || !set.started);
  }

  startUndo() {
    this.undoStack.push({ filled: !!this.filled, user_modified: !!this.user_modified });
    this.games.forEach((game) => game.startBatchUndo());
  }

  endUndo() {
    this.games.forEach((game) => game.endBatchUndo());
  }

  async withUndo<T>(fn: () => T | Promise<T>): Promise<T> {
    this.startUndo();
    const result = await fn();
    this.endUndo();
    return result;
  }

  get canUndo() {
    const canUndoStarted = this.started && this.isLastStartedSet;
    const canUndoNotStarted = !this.started && this.games.some((game) => game.canUndo);
    return canUndoStarted || canUndoNotStarted;
  }

  undo() {
    if (this.started) {
      return this.reset();
    }

    this.games.forEach((game) => game.undo());

    const undo = this.undoStack.pop()!;
    if (undo) {
      this.filled = undo.filled;
      this.user_modified = undo.user_modified;
    }
  }

  get canRefill() {
    return (
      this.night.players.length > 1 &&
      (this.games.every((game) => game.full()) || this.night.players.length - this.playerIds().length < 2)
    );
  }

  async refill() {
    if (!this.canRefill) return;

    this.startUndo();
    this.unlockedGames.forEach((game) => game.clear());
    await this.fill();
    this.endUndo();
  }

  async fill() {
    this.withUndo(() => {
      this.filled = true;

      const filler = new GameSetFiller(this);
      filler.fill();

      this.endUndo();
    });

    await this.save();
  }

  playerGame(playerId: string) {
    return this.games.find((game) => game.playerIds.includes(playerId));
  }

  playerGameIndex(playerId: string) {
    for (let i = 0; i < this.games.length; i++) {
      const game = this.games[i];
      if (!game.players?.current) continue;
      const playerIndex = game.players.current.indexOf(playerId);
      if (playerIndex === -1) continue;
      return `${this.index}-${i}-${playerIndex}`;
    }

    return undefined;
  }

  activePlayerIds() {
    return this.games.flatMap((game) => game.activePlayerIds());
  }

  playerIds() {
    return this.games.flatMap((game) => game.playerIds).filter(isPresent);
  }

  override async save() {
    await super.save();
    if (this.games.loaded) {
      await Promise.all(this.games.map((game) => game.save()));
    }
  }

  /**
   * Game can start if the previous game has started and next game has not yet
   * started
   */
  get canStart(): boolean {
    if (isNil(this.index)) return false;

    let gameSets = this.night.game_sets;
    let firstSet = this.index === 0;
    let previousGameSetStarted = this.index !== 0 && gameSets[this.index - 1]?.started;
    let nextGameSetNotStarted = !gameSets[this.index + 1]?.started;

    return (firstSet || previousGameSetStarted) && nextGameSetNotStarted;
  }

  get running(): boolean {
    return Boolean(this.started_at && !this.stopped_at);
  }

  get started(): boolean {
    return isPresent(this.started_at);
  }

  get finished(): boolean {
    return this.started && isPresent(this.stopped_at);
  }

  get duration() {
    if (this.started_at) {
      if (this.stopped_at) {
        return Math.abs(dayjs(this.stopped_at).diff(dayjs(this.started_at)));
      } else {
        return Math.abs(dayjs().diff(dayjs(this.started_at)));
      }
    } else {
      return 0;
    }
  }

  async start() {
    if (!this.started_at) {
      this.started_at = new Date();
    } else if (this.stopped_at) {
      this.started_at = new Date(new Date().valueOf() - this.duration);
    }
    this.stopped_at = undefined;

    await Promise.all([
      this.night.stop_warm_up(),
      ...this.night.game_sets.filter((set) => set.id !== this.id).map((set) => set.stop()),
      this.save(),
    ]);

    await this.night.autoFillNextSet();
  }

  stop() {
    this.stopped_at = new Date();
    return this.save();
  }

  reset() {
    this.started_at = undefined;
    this.stopped_at = undefined;
    return this.save();
  }

  hasPlayer(id: string) {
    return this.games.some((game) => game.playerIds.includes(id));
  }

  hasPlayers() {
    return this.games.some((game) => game.playerCount > 0);
  }

  playerPosition(id: string) {
    for (let i = 0; i < this.games.length; i++) {
      const game = this.games[i];
      const index = game.playerIds.indexOf(id);
      if (index !== -1) {
        return [i, index];
      }
    }
  }

  async setPlayerContainerGame(gameIndex: number, playerIndex: number, container: PlayerContainer) {
    this.startUndo();

    const { games } = this;
    const game = games[gameIndex];

    const [firstPlayer, secondPlayer] = container;

    // Determine the target slots based on the playerIndex and the number of players in the container
    const targetStartIndex = container.length === 2 ? (playerIndex < 2 ? 0 : 2) : playerIndex;

    // Store original player IDs for potential swaps
    const originalPlayerId = game.playerIds[targetStartIndex];

    // Check if the first player in the container is currently assigned to another game
    const firstPlayerOriginalPosition = this.playerPosition(firstPlayer.id!);

    // Replace player in the target slot
    game.setPlayerAt(targetStartIndex, firstPlayer.id);

    // Handle swap if the first player is from a different game
    if (firstPlayerOriginalPosition) {
      this.games[firstPlayerOriginalPosition[0]].setPlayerAt(
        firstPlayerOriginalPosition[1],
        originalPlayerId,
      );
    }

    // If there are two players in the container, handle the second player
    if (secondPlayer) {
      const originalSecondPlayerId = game.playerIds[targetStartIndex + 1];
      const secondPlayerOriginalPosition = this.playerPosition(secondPlayer.id!);

      game.setPlayerAt(targetStartIndex + 1, secondPlayer.id);

      // Handle swap if the second player is from a different game
      if (secondPlayerOriginalPosition) {
        this.games[secondPlayerOriginalPosition[0]].setPlayerAt(
          secondPlayerOriginalPosition[1],
          originalSecondPlayerId,
        );
      }
    }

    this.endUndo();
    await this.games.save();
  }

  async setPlayerGame(gameIndex: number, playerIndex: number, playerId: string) {
    const player = this.night.players.find((p) => p.id === playerId);
    if (!player) return;
    await this.setPlayerContainerGame(gameIndex, playerIndex, [player]);
  }

  async removePlayerContainer(players: PlayerContainer) {
    for (const player of players) {
      await this.removePlayer(player.id!);
    }
  }

  async removePlayer(playerId: string) {
    this.games.map((game) => {
      game.removePlayer(playerId);
    });

    await this.games.save();
  }

  async userModified() {
    this.user_modified = true;
    await this.save();
  }

  get title() {
    return `Set ${this.index! + 1}`;
  }

  override async create() {
    if (isNil(this.index) || this.index < 0) {
      throw new Error("Invalid set index");
    }

    return super.create();
  }
}

export default GameSet;
