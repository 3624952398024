import { Link, Button } from "@mixitone/components";
import { AppController } from "controllers/AppController";
import MixitoneController from "controllers/MixitoneController";
import SessionController from "controllers/SessionController";
import { routeHelpers } from "controllers/routes";
import { ApplicationView } from "@mixitone/mvc";
import ClubMenu from "./ClubMenu";
import UserMenu from "./UserMenu";

interface State {}

export class TopNavController extends MixitoneController<State> {
  async initialize() {}
}

const TopNav = () => {
  const sessionController = SessionController.use();
  const appController = AppController.use();
  const { currentClub } = appController.state;
  const { loginState } = sessionController.state;

  return (
    <div className="relative z-20 flex self-end flex-shrink-0 gap-2" role="navigation">
      {loginState === "signed-out" && (
        <Link href={routeHelpers.login({})}>
          <span>Log in</span>
        </Link>
      )}
      {sessionController.signedIn && (
        <>
          {currentClub && <ClubMenu />}
          <UserMenu />
        </>
      )}
      {loginState === "unknown" && (
        <Button disabled spinner>
          Log in
        </Button>
      )}
    </div>
  );
};

export default TopNavController.scope(ApplicationView(TopNav));
