import { SupabaseClient, createClient } from "@supabase/supabase-js";
import { Database } from "./database.types";

export const supabaseOptions = {
  url: import.meta.env.VITE_SUPABASE_URL,
  anonKey: import.meta.env.VITE_SUPABASE_ANON_KEY,
};

let supabase: SupabaseClient<Database>;

export function createSupabase(persistSession = true) {
  const nativeFetch = typeof window === "undefined" ? fetch : window.fetch;

  // When running in the browser use localStorage. On Tauri use Tauri storage so
  // it can share the access token
  let authStorage: Storage | undefined;

  if (typeof window !== "undefined") {
    authStorage = window.localStorage;

    // if (window.__TAURI__) {
    // const tauriStore = new Store(".session.dat");
    // await tauriStore.load();
    // authStorage = new TauriStorage(tauriStore);
    // console.log(authStorage);
    // }
  }

  if (supabase) return supabase;

  const { url, anonKey } = supabaseOptions;
  supabase = createClient<Database>(url, anonKey, {
    global: {
      fetch: nativeFetch,
    },
    auth: { persistSession, storage: authStorage },
  });
  // supabase.auth.logDebugMessages = true;
  return supabase;
}

export async function sessionForWorker() {
  const auth = await createSupabase().auth.refreshSession();
  const session = auth?.data?.session;
  return session;
}
