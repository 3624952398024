import { RouteMatcher } from "lib/RouteMatcher";
import ApplicationController from "@mixitone/mvc";
import { routes, ComponentRoute } from "./routes";
import SessionController from "./SessionController";
import React from "react";
import NotFound from "components/Pages/NotFound/NotFound";

interface State {
  pageComponents: PageComponent[];
  childComponents: PageComponent[];
  url: string;
}

interface Props {}

export interface MatchedRoute {
  route: ComponentRoute;
  path: string;
  segments: Record<string, string>;
}

interface PageComponent {
  component: React.ComponentType<any>;
  segments: Record<string, string>;
  path: string;
}

export default class RouteController extends ApplicationController<State, Props> {
  router!: RouteMatcher;
  routeStack: MatchedRoute[] = [];
  urlAfterLogin: string | null = null;

  override get initialState(): State {
    return {
      pageComponents: [],
      childComponents: [],
      url: "/",
    };
  }

  get loggedIn() {
    return this.get(SessionController).state.loginState === "signed-in";
  }

  get requiresLogin() {
    return this.routeStack.some((route) => route.route.requiresLogin);
  }

  override async initialize(props: Props) {
    super.initialize(props);

    this.router = new RouteMatcher();

    Object.entries(routes).forEach(([path, componentRoute]: [string, ComponentRoute]) => {
      this.router.on(path, (matchedPath, segments) =>
        this.matchedRoute({
          path: matchedPath,
          segments,
          route: componentRoute,
        }),
      );
    });

    if (window.navigation) {
      // @ts-ignore
      navigation?.addEventListener("navigate", (event: NavigateEvent) => {
        if (event.canIntercept && event.intercept && event.navigationType !== "reload") {
          event.intercept({
            handler: async () => {
              this.debug("handle route via intercept", event.destination);
              await this.handleRoute(new URL(event.destination.url));
            },
          });
        }
      });
    } else {
      window.addEventListener("popstate", this.handlePopstate);
    }

    this.observeLoginState();
    this.handlePopstate();
  }

  override destroy(): void {
    window.removeEventListener("popstate", this.handlePopstate);
  }

  observeLoginState() {
    this.observe(() => {
      if (this.requiresLogin && !this.loggedIn) {
        this.debug("requires log-in but not logged in");
        this.urlAfterLogin = window.location.pathname;
        this.actionForward("/login");
      }
    });
  }

  handlePopstate = () => {
    this.debug("handle route via popstate", window.location.href);
    this.handleRoute(new URL(window.location.href));
  };

  async handleRoute(url: URL) {
    this.clearRouteStack();
    this.router.match(url.pathname, true);

    if (this.routeStack.length === 0) {
      console.log("No route found for", url.pathname);
      this.state.pageComponents = [
        {
          component: NotFound,
          path: "/not_found",
          segments: {},
        },
      ];
      this.state.childComponents = [];
      return;

      // return this.actionForward("/not_found");
    }

    if (!this.loggedIn && this.requiresLogin) {
      await this.get(SessionController).waitForSession();

      if (!this.loggedIn) {
        this.urlAfterLogin = url.pathname;
        this.debug("after login forward back to login");
        return this.actionForward("/login");
      }
    }

    this.state.url = url.pathname || "/";
    this.buildPageComponents();
  }

  async buildPageComponents() {
    const pageComponents: PageComponent[] = [];
    const childComponents: PageComponent[] = [];
    const componentImports: Promise<any>[] = [];

    for (const route of this.routeStack) {
      const componentImport = route.route.component();
      componentImports.push(componentImport);

      if (route.route.child) {
        childComponents.push({
          component: React.lazy(() => componentImport),
          ...route,
        });
      } else {
        pageComponents.push({
          component: React.lazy(() => componentImport),
          ...route,
        });
      }
    }

    await Promise.all(componentImports);
    this.state.childComponents = childComponents;
    this.state.pageComponents = pageComponents;
  }

  get hasChildComponents() {
    return this.state.childComponents.length > 0;
  }

  clearRouteStack() {
    this.routeStack = [];
  }

  matchedRoute(route: MatchedRoute) {
    this.routeStack.push(route);
    return !route.route.stop;
  }

  async actionForward(to: string, { replace }: { replace?: boolean } = {}) {
    this.debug("actionForward", to, { replace });

    if (window.navigation) {
      await navigation?.navigate(to, { state: { replace } }).finished;
    } else {
      window.history.pushState({}, "", to);
      this.handlePopstate();
    }
  }

  async actionBack() {
    // If we have a route stack, shift the last route off and rebuild the page
    // components
    if (this.routeStack.length > 1) {
      this.routeStack.shift();
      window.history.pushState({}, "", "/" + this.routeStack[0].path);
      this.buildPageComponents();
      return;
    }

    if (window.navigation?.canGoBack) {
      try {
        await navigation?.back().finished;
      } catch (e) {
        this.debug("Error going back", e);
        await this.actionForward("/");
      }
    } else {
      history.back();
    }
  }
}
