import Layout from "components/Layout/Layout";
import SessionController from "controllers/SessionController";
import { ApplicationView } from "@mixitone/mvc";
import Footer from "../Home/Footer";
import { HomeMenu } from "../Home/HomeMenu";

const NotFound = () => {
  const controller = SessionController.use();
  const menu = controller.signedIn ? null : <HomeMenu />;

  return (
    <>
      <div className="h-full w-full bg-gray-50">
        <Layout menu={menu}>
          <div className="flex flex-col">
            <div className="sm:py-18 container relative mx-auto px-6 py-16 pb-0 pt-24 md:py-24 lg:px-16 lg:py-24 xl:px-20">
              <div className="relative">
                <main className="">
                  <div className="mx-auto">
                    <h1 className="text-scale-1200 text-2xl sm:text-3xl sm:leading-none lg:text-4xl xl:text-5xl">
                      Oops, the page you're looking for cannot be found.
                    </h1>
                    <p className="p mt-1.5 text-sm sm:mt-5 sm:text-base lg:text-lg ">
                      Please check the URL and try again. If you were looking for an old session it may have
                      expired.
                    </p>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </Layout>
      </div>
      <Footer />
    </>
  );
};

export default ApplicationView(NotFound);
