import { ApplicationController, observe } from "@mixitone/mvc";
import { AuthEvent, Session, SessionAdapter } from "lib/session/SessionAdapter";
import { SupabaseSessionAdapter } from "lib/session/SupabaseSessionAdapter";
import { TauriSessionAdapter } from "lib/session/TauriSessionAdapter";
import { createSupabase } from "lib/supabaseClient";
import tauri from "lib/tauri";

interface State {
  loginState: LoginState;
  session: Session | null;
}

interface Props {}

export type LoginState = "unknown" | "signing-up" | "signed-in" | "signed-out" | "password-recovery";

export default class SessionController extends ApplicationController<State, Props> {
  adapter!: SessionAdapter;

  override get initialState(): State {
    // @ts-ignore
    return { loginState: "unknown", session: null };
  }

  override async initialize(props: Props) {
    super.initialize(props);

    if (tauri.enabled) {
      const adapter = new TauriSessionAdapter();
      await adapter.initialize();
      this.adapter = adapter;
    } else {
      const supabase = createSupabase();
      this.adapter = new SupabaseSessionAdapter(supabase);
    }
    this.adapter.onAuthStateChange(async (event, session) => {
      // Block updates while signing up
      if (this.signingUp) return;

      switch (event) {
        case AuthEvent.INITIAL_SESSION:
        case AuthEvent.SIGNED_IN:
        case AuthEvent.TOKEN_REFRESHED:
          this.actionSetSession(session);
          break;
        case AuthEvent.SIGNED_OUT:
          this.actionSetSession(null);
          break;
        case AuthEvent.PASSWORD_RECOVERY:
          this.state.loginState = "password-recovery";
          break;
      }
    });
  }

  async waitForSession(): Promise<State["loginState"]> {
    if (this.state.loginState !== "unknown") return this.state.loginState;

    return new Promise((resolve) => {
      const observer = observe(() => {
        if (this.state.loginState !== "unknown") {
          observer();
          resolve(this.state.loginState);
        }
      });
    });
  }

  actionSigningUp() {
    this.state.loginState = "signing-up";
  }

  async actionCompleteSignUp(session: Session) {
    this.state.loginState = "unknown";
    return this.actionSetSession(session);
  }

  async actionSetSession(session: Session | null) {
    if (session && session === this.state.session) return;

    // If the session changed but the user did not and loading is true then just
    // set the session and return
    if (session?.user?.id === this.state.session?.user?.id && this.state.loginState === "signed-in") {
      this.debug("session changed but user did not");
      this.state.session = session;
      return;
    }

    this.state.session = session;

    if (session) {
      if (this.state.loginState === "signing-up") {
        // If the user is signing up then we don't need to load the data right now
        this.debug("user is signing up");
        return;
      }

      this.state.loginState = "signed-in";
    } else {
      this.debug("no session found");
      this.state.loginState = "signed-out";
    }
  }

  async actionSignOut() {
    this.state.loginState = "signed-out";
    await this.adapter.signOut();
    await this.actionSetSession(null);
  }

  get signedIn() {
    return this.state.loginState === "signed-in";
  }

  get signingUp() {
    return this.state.loginState === "signing-up";
  }

  get isAnonymous() {
    return this.state.session?.user?.is_anonymous;
  }

  get user() {
    return this.state.session?.user;
  }
}
