import SplitScreenLayout from "components/Layout/SplitScreenLayout";
import TauriController from "controllers/TauriController";
import { ApplicationView } from "@mixitone/mvc";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Sync: React.FC = () => {
  const controller = TauriController.use();
  const { syncProgress } = controller.state;

  return (
    <SplitScreenLayout title="Syncing your account" showBack={false}>
      <div>
        <p className="text-gray-900">Your account is being synced. This may take a few moments.</p>
        <div style={{ width: 128, height: 128 }}>
          <CircularProgressbarWithChildren value={syncProgress} text={`${syncProgress}%`} />
        </div>
      </div>
    </SplitScreenLayout>
  );
};

export default ApplicationView(Sync);
