import { Button, ButtonGroup, Link } from "@mixitone/components";
import CloseIcon from "@mixitone/components/icons/close.svg";
import { ApplicationView } from "@mixitone/mvc";
import clsx from "clsx";
import { AppController } from "controllers/AppController";
import MixitoneController from "controllers/MixitoneController";
import dayjs from "dayjs";
import { useCallback } from "react";

interface State {
  displayNotification?: boolean;
}

const CHECK_INTERVAL = 1000 * 60;

class OfflineNotificationController extends MixitoneController<State> {
  get initialState(): State {
    return {
      displayNotification: false,
    };
  }

  remindAtCheckInterval?: ReturnType<typeof setInterval>;

  override async initialize() {
    this.remindAtCheckInterval = setInterval(() => {
      this.updateDisplayNotification();
    }, CHECK_INTERVAL);
    this.addDependency(() => {
      clearInterval(this.remindAtCheckInterval);
    });

    this.observe(() => {
      this.updateDisplayNotification();
    });
  }

  updateDisplayNotification() {
    const remindAtFromStorage = localStorage.getItem("remindAt");
    if (remindAtFromStorage) {
      const remindAtInFuture = dayjs().isBefore(dayjs(remindAtFromStorage));
      if (remindAtInFuture) {
        this.state.displayNotification = false;
        return;
      }
    }

    this.state.displayNotification =
      this.get(AppController).state.online && this.get(AppController).state.updateAvailable;
  }

  actionRemindMeLater() {
    localStorage.setItem("remindAt", dayjs().add(1, "day").toISOString());
    this.updateDisplayNotification();
  }
}

const OfflineNotification: React.FC = () => {
  const controller = OfflineNotificationController.use();
  const appController = AppController.use();

  const { displayNotification } = controller.state;
  const { updateAvailable } = appController.state;

  const handleUpdater = useCallback(() => {
    appController.actionCheckForUpdates();
  }, [appController]);

  const handleRemindMeLater = useCallback(() => {
    controller.actionRemindMeLater();
  }, [controller]);

  return (
    <div
      className={clsx(
        "dark fixed bottom-4 right-4 z-40 w-96 rounded-xl bg-slate-800 p-8 text-white shadow-xl",
        "transition-opacity duration-300",
        {
          "opacity-0": !displayNotification,
          "opacity-100": displayNotification,
        },
      )}
    >
      <button className="absolute right-4 top-4">
        <CloseIcon
          onClick={handleRemindMeLater}
          className=" fill-slate-400 transition-transform hover:scale-125 hover:fill-slate-200 sm:w-[25px]"
        />
      </button>

      {!updateAvailable && (
        <div className="flex flex-col w-full gap-2">
          <p>Ready to sync: Your recent gameplay stats and player updates are waiting to be sent online.</p>
          <p>Connect to the internet soon to keep everything up to date!</p>
          <div className="flex justify-center mt-4">
            <Link target="_blank" href={`${import.meta.env.VITE_WWW}/docs/game-sessions/sessions`}>
              Read more
            </Link>
          </div>
        </div>
      )}

      {updateAvailable && (
        <div className="flex flex-col w-full gap-2">
          <p>An update is available. Download the latest version.</p>
          <div className="flex justify-end mt-2">
            <ButtonGroup>
              <Button onClick={handleUpdater}>Update now</Button>
              <Button kind="secondary" onClick={handleRemindMeLater}>
                Remind me later
              </Button>
            </ButtonGroup>
          </div>
        </div>
      )}
    </div>
  );
};

export default OfflineNotificationController.scope(ApplicationView(OfflineNotification));
