import { Model, ModelAttributes, model, modelConfig } from "@mixitone/oom";

const config = modelConfig(
  {
    account_id: { type: "string" },
    club_id: { type: "string" },
    club_player_id: { type: "string" },
    created_at: { type: "datetime" },
    group_id: { type: "string" },
    group_ids: { type: "string[]" },
    is_deleted: { type: "boolean" },
    player_ids: { type: "string[]" },
    rule_type: { type: "string" },
    updated_at: { type: "datetime" },
  },
  {
    // Add collections here if needed
  },
  {
    // Add references here if needed
  },
);

interface FillRule extends ModelAttributes<typeof config> {}

export type RuleType = "never" | "only" | "prefers";

@model("fill_rules", config)
class FillRule extends Model<typeof config> {
  get subjectType() {
    if (this.group_id) {
      return "Group";
    }
    if (this.club_player_id) {
      return "Player";
    }
  }
}

export default FillRule;
