import { Spinner } from "@mixitone/components";
import App from "App";
import clsx from "clsx";

import Sync from "components/Pages/Sync/Sync";
import SessionController from "controllers/SessionController";
import TauriController from "controllers/TauriController";
import { ApplicationView } from "@mixitone/mvc";
import tauri from "lib/tauri";

const Tauri: React.FC = () => {
  const controller = TauriController.use();
  const sessionController = SessionController.use();
  const { loginState } = sessionController.state;
  const { ready } = controller;

  if (tauri.enabled && loginState === "unknown" && !ready) {
    return <Sync />;
  }

  if (loginState === "unknown") {
    return (
      <div className={clsx("h-screen bg-brand-600")}>
        <div
          className={clsx(
            "flex h-full w-full items-center justify-center bg-slate-800 bg-gradient-to-r from-slate-700 to-slate-800 sm:flex-col sm:bg-gradient-to-b",
          )}
        >
          <Spinner size={128} className="fill-brand-600" />
        </div>
      </div>
    );
  }

  return <App />;
};

export default TauriController.scope(ApplicationView(Tauri));
