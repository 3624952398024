import { Model, ModelAttributes, model, modelConfig, reference } from "@mixitone/oom";
import { Loadable } from "@mixitone/oom/reference";
import { Database } from "./database.types";
import Account from "./Account";

const config = modelConfig(
  {
    account_id: {
      type: "string",
    },
    created_at: {
      type: "datetime",
    },
    is_deleted: {
      type: "boolean",
    },
    name: {
      type: "string",
    },
    role: {
      type: "enum",
      enumType: "Admin" as Database["public"]["Enums"]["user_role"],
    },
    updated_at: {
      type: "datetime",
    },
    user_id: {
      type: "string",
    },
  },
  {},
  {
    account: reference<any>(() => Account as any, "account_id"),
  },
);

interface AccountUser extends ModelAttributes<typeof config> {
  account: Loadable<Account>;
}

@model("account_users", config)
class AccountUser extends Model<typeof config> {
  static current: AccountUser | null = null;

  static byId(uid: string) {
    return AccountUser.query().find(uid);
  }

  static forUser(uid: string) {
    return AccountUser.query().eq("user_id", uid).preload("account").limit(5).all();
  }

  override canDestroy() {
    return this.role === "Manager";
  }
}

export default AccountUser;
