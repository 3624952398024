/**
 * An implementation of the Maybe monad.
 */
export default function Maybe<T>(value: T | null | undefined): Maybe<T> {
  return new MaybeImpl(value);
}

export function Some<T>(value: T): Maybe<T> {
  return Maybe(value);
}

export function None<T>(): Maybe<T> {
  return Maybe(null);
}

export function MaybeTry(fn: () => any) {
  try {
    return Maybe(fn());
  } catch (error) {
    return Maybe(null);
  }
}

export interface Maybe<T> {
  map<U>(fn: (value: T) => U): Maybe<NonNullable<U>>;
  tryMap<U, E>(fn: (value: T) => U, err: (error: E) => void): Maybe<NonNullable<U>>;
  tryMap<U>(fn: (value: T) => U): Maybe<NonNullable<U>>;
  flatMap<U>(fn: (value: T) => Maybe<U>): Maybe<U>;
  chain<U>(fn: (value: T) => Maybe<U>): Maybe<U>;
  getOrElse<U>(defaultValue: U | (() => U)): T | U;
  get(): T | null;
  isPresent(): boolean;
  isAbsent(): boolean;
  filter(predicate: (value: T) => boolean): Maybe<T>;
  orElse<U>(defaultValue: U | (() => U)): Maybe<T | U>;
  and<U>(maybe: Maybe<U>): Maybe<U>;
  fold<U, E>(fn: (value: T) => U, nullfn: () => E): U | E;
}

class MaybeImpl<T> implements Maybe<T> {
  value!: T | null;

  constructor(value: T | null | undefined) {
    if (value === undefined) {
      return Maybe(null);
    }

    this.value = value;
  }

  map<U>(fn: (value: T) => U): Maybe<NonNullable<U>> {
    return this.value == null ? Maybe(null) : Maybe(fn(this.value));
  }

  tryMap<U, E = Error>(fn: (value: T) => U, err?: (error: E) => void): Maybe<NonNullable<U>> {
    try {
      return this.map(fn);
    } catch (error) {
      if (err) {
        err(error as E);
      }
      return Maybe(null);
    }
  }

  flatMap<U>(fn: (value: any) => Maybe<U>): Maybe<U> {
    return this.value == null ? Maybe(null) : fn(this.value);
  }

  chain<U>(fn: (value: any) => Maybe<U>): Maybe<U> {
    return this.flatMap(fn);
  }

  getOrElse<U>(defaultValue: U | (() => U)): T | U {
    if (this.value === null) {
      if (defaultValue instanceof Function) {
        return defaultValue();
      }
      return defaultValue;
    }

    return this.value;
  }

  get(): T | null {
    return this.value;
  }

  isPresent(): boolean {
    return this.value != null;
  }

  isAbsent(): boolean {
    return this.value == null;
  }

  filter(predicate: (value: any) => boolean): Maybe<T> {
    return this.flatMap((value) => (predicate(value) ? Maybe(value) : Maybe(null)));
  }

  orElse<U>(defaultValue: NonNullable<U> | (() => NonNullable<U>)): Maybe<T | U> {
    if (this.value == null) {
      if (defaultValue instanceof Function) {
        return Maybe(defaultValue());
      }
      return Maybe(defaultValue);
    }

    return this;
  }

  and<U>(maybe: Maybe<U>): Maybe<U> {
    return this.flatMap(() => maybe);
  }

  [Symbol.iterator](): Iterator<T> {
    const a = this.value === null ? [] : [this.value];
    return a[Symbol.iterator]();
  }

  fold<U, E>(fn: (value: T) => U, nullfn: () => E): U | E {
    if (this.value != null) {
      return fn(this.value);
    } else {
      return nullfn();
    }
  }
}
